::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--chakra-colors-black-200);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--chakra-colors-black-200);
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  display: none;
}
